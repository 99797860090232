<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Position detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="item">
      <h2 class="mb-2">Scenarios</h2>
      <data-table-ssr id="scenario_list" ref="scenario_list" :limit-base="36" :pagination="false"
        :columns="scenario_fields" :get-list-fn="getScenarios" :create_schema="scenario_schema"
        :create-row-fn="createScenario" :to-edit-fn="toScenarioDetail" :delete-row-fn="deleteScenario"
        :more_actions="[{ text: 'Create one above', icon: 'PlusCircleIcon', handler: createScenarioAbove, }]" />
    </section>
    <action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
      @delete="deleteItem" :is-deleting="isDeleting" />
  </div>
</template>
<script>
import service from '../service';
import scenarioService from '../../scenario/service';

const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Key', field: 'key', disabled: true },
      { label: 'Name', field: 'name', input_type: 'ui-component' },
    ]
  }
];

const scenario_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
      { label: 'Background', field: 'background', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title', input_type: 'text', validate: { required: true }, },
    ]
  }
];
const scenario_fields = [
  { label: 'Title', field: 'title' },
  { label: 'Image', field: 'image', input_type: 'image' },
  { label: 'Background', field: 'background', input_type: 'image' },
  { label: 'Created At', field: 'createdAt', input_type: 'date' },
];

export default {
  data() {
    return {
      item_form_schema,
      scenario_fields,
      scenario_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
      triggers: null,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Career list',
          to: { name: 'ai_career-list' },
        },
        {
          text: `${this.item ? this.item.key : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    // position
    async getItem() {
      this.item = await service.get({ id: this.itemId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "ai_career-list" });
    },
    // scenario
    async createScenario(data) {
      let new_item = await scenarioService.create({
        ...data,
        career_field: this.item.career_field,
        position: this.item.key,
      });
      return new_item;
    },
    async createScenarioAbove({ item }) {
      this.$refs.scenario_list.openCreatePopup({ display_order: item.display_order - 0.5 });
    },
    async getScenarios({ limit, page, query }) {
      let data = await scenarioService.getList({
        query: JSON.stringify({ ...query, position: this.item.key }),
        limit, page
      });
      let list = [], total = 0;
      if (data) {
        list = data.data.data.list;
        total = data.data.data.total;
      }
      return { list, total };
    },
    async deleteScenario({ _id }) {
      await scenarioService.delete({ id: _id });
    },
    toScenarioDetail({ _id }) {
      this.$router.push({ name: 'ai_scenario-detail', params: { id: _id } });
    },
  },
};
</script>
